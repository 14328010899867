import React from "react";
import { Therapist } from "../pages/Therapists/types";
import yeo from "../assets/img/therapist/yeo.png";
import seo from "../assets/img/therapist/seo.jpeg";
import no from "../assets/img/therapist/no.jpeg";
import ok from "../assets/img/therapist/ok.jpeg";
import hyun from "../assets/img/therapist/hyun.jpeg";
import sung from "../assets/img/therapist/sung.jpeg";
import styled from "styled-components";

const Container = styled.div``;

const Header = styled.p`
  font-weight: 800;
  text-decoration: underline;
  font-size: 15px;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: 12px;
`;

const SungDescription: React.FC = () => {
  return (
    <Container>
      <Header>학위</Header>
      <Description>
        한신대학교 정신분석대학원 석사
        <br />
        한양대학교 상담심리학과 졸업
      </Description>
      <Header>경력</Header>
      <Description>
        현, 동탄가정심리상담소 놀이치료사
        <br />
        점토치료사
        <br />
        놀이치료사
      </Description>
      <Header>자격증</Header>
      <Description>
        사회복지사 2급
        <br />
        미술심리상담사 1급
      </Description>
    </Container>
  );
};

const HyunDescription: React.FC = () => {
  return (
    <Container>
      <Header>학위</Header>
      <Description>
        호서대학교 일반대학원 “목회상담학” 박사 재학 중 (Ph.D.)
        <br />
        호서대학교 연합신학전문대학원 “목회상담학” 석사 졸업 (MTS)
        <br />
        충남대학교 일반대학원 언어병리학과 석사 수료
        <br />
        우송대학교 언어치료학과
      </Description>
      <Header>경력</Header>
      <Description>
        현 호서대학교 일반대학원 목회상담학과 독서클럽 패널교수
        <br />
        현 동탄가정심리상담소 언어치료사 · 정신분석상담사
        <br />
        현 이화아동발달교육원 언어치료사
        <br />
        전 대덕구장애인종합복지관 언어치료사
        <br />
        한국언어재활사협회 정회원
        <br />
        보건복지부 언어재활사
      </Description>
      <Header>논문</Header>
      <Description>
        『성서와 정신분석』 학회지에 실린 논문
        <br />
        “공격성에 대한 정신분석학적 고찰”
        <br />
        “애도에 대한 정신분석학적 고찰”
      </Description>
    </Container>
  );
};

const OkDescription: React.FC = () => {
  return (
    <Container>
      <Header>학위</Header>
      <Description>
        호서대학교 일반대학원 “목회상담학/정신분석” 박사 (Ph.D.)
        <br />
        호서대학교 연합신학전문대학원 “목회상담학/정신분석” 석사 (M.T.S)
      </Description>
      <Header>경력</Header>
      <Description>
        수원가정심리상담소 센터장
        <br />
        P.E.T 과정 · 의사소통 기술훈련
        <br />
        미술치료교육사
        <br />
        인간관계와 자기표현
        <br />
        가정폭력·성폭력 전문 상담
        <br />
        이음부모 교육 지도자
        <br />
        솔리언 또래 상담 지도자
      </Description>
      <Header>자격증</Header>
      <Description>
        정신분석가
        <br />
        심리상담사 1급
        <br />
        평생교육사 2급
        <br />
        사회복지사 2급
        <br />
        가정폭력·성폭력 전문 상담사
        <br />
        미술치료교육사
        <br />
        리더십지도자
        <br />
        이음부모 교육 지도자
        <br />
        솔리언 또래 상담 지도자
      </Description>
      <Header>논문</Header>
      <Description>
        『 The Psychoanalytic Biblical Studies 』 학회지에 실린 논문
        <br />
        표상에 대한 정신분석학적 고찰
        <br />
        표상에 대한 정신분석학적 연구
        <br />
        강박증의 치료적 변화에 대한 정신분석학적 고찰
        <br />
        시기심에 대한 목회상담학적 고찰
        <br />
        영성과 정신분석이 표상에 미치는 영향
      </Description>
    </Container>
  );
};

const NoDescription: React.FC = () => {
  return (
    <Container>
      <Header>학위</Header>
      <Description>
        호서대학교 일반대학원 신학과 “목회상담학/정신분석” 박사수료
        <br />
        호서대학교 연합신학대학원 “목회상담학” 석사 (Th.M.)
        <br />
        영남대학교 가정관리학과 졸업
      </Description>
      <Header>경력</Header>
      <Description>
        중등정교사 2급자격증취득
        <br />
        경산여고, 점촌고등학교 근무
        <br />
        가족상담사 1급자격증취득
        <br />
        동탄 부모교육프로그램 강사
      </Description>
      <Header>논문</Header>
      <Description>
        『성서와 정신분석』 학회지에 실린 논문
        <br />
        “기억과 억압에 관한 정신분석과 신경과학적 접근”
        <br />
        “증상의 의미에 관한 정신분석적 고찰”
        <br />
        “구조에 대한 정신분석적 고찰”
        <br />
        “라깡의 언어와 무의식”
      </Description>
    </Container>
  );
};

const SeoDescription: React.FC = () => {
  return (
    <Container>
      <Header>학위</Header>
      <Description>
        호서대학교 일반대학원 신학과 정신분석전공 박사수료
        <br />
        호서대학교 연합신학대학원 목회상담학 석사(Th.M.)
      </Description>
      <Header>경력</Header>
      <Description>
        정신분석아카데미 수원 전문독서클럽 패널교수
        <br />
        부부가족상담 부모교육협회 청소년 상담국장
        <br />
        동탄가정심리상담소 부모교육 워크샵 강사
        <br />
        기업체(KT) 지정 임직원 가족상담사
        <br />
        삼성노블카운티 미술치료 자격증반 강사
      </Description>
      <Header>자격증</Header>
      <Description>
        가족상담사 1급 자격증
        <br />
        리더십지도사 자격증
      </Description>
      <Header>논문</Header>
      <Description>
        석사논문 “불안의 선순환에 관한 연구” (2013)
        <br />
        학술논문 "믿음의 공간으로서의 실재계의 관한 연구" (2019)
      </Description>
    </Container>
  );
};

const YeoDescription: React.FC = () => {
  return (
    <Container>
      <Header>학위</Header>
      <Description>
        호서대학교 연합신학대학원 “목회상담학” 박사 (Ph.D.)
        <br />
        호서대학교 연합신학대학원 “목회상담학” 석사 (Th.M.)
        <br />
        광운대학교 정보복지대학원 “심리치료학” 석사
      </Description>
      <br />
      <Header>경력</Header>
      <Description>
        호서대학교 연합신학대학원 목회상담학과 임상패널교수
        <br />
        국제임상목회대학원 지도교수
        <br />
        화성시 범죄피해자지원협의회위원 위촉
        <br />
        서울지역 이혼 조정위원 위촉 제2004-026
        <br />
        경기노인학대예방센타 사례판정위원
        <br />
        한국여성의전화 가정폭력상담센타 소장
        <br />
        정신분석상담학회 이사
        <br />
        분당 샘물교회 담임목사 역임
        <br />
        동탄 행복이가득한교회 담임목사 역임
        <br />
        동탄빛교회 공동목회
        <br />
        Chaplain healing counseling Mission center 원장
        <br />
        동탄가정심리상담소 센터장
      </Description>
      <br />
      <Header>저서</Header>
      <Description>
        「삐딱한 성장/결혼 바로 세우기」하나의학사
        <br />
        「상담 심리학」양성사
      </Description>
      <br />
      <Header>논문</Header>
      <Description>
        박사논문 “긴장에너지의 창조적 역학을 통한 자기성장에 관한 연구”
        <br />
        석사논문 “가정폭력에 관한 정신분석학적 연구”
        <br />
        『정신분석과 상담』학회지에 실린 논문
        <br />
        “긴장방출에 관한 정신분석학적 연구”- 프로이트 관점
        <br />
        “긴장방출에 관한 정신분석학적 연구”- 멜라니 클라인 관점
        <br />
        “긴장방출에 관한 정신분석학적 연구”- 위니캇 관점
        <br />
        “긴장방출에 관한 정신분석학적 연구”- 페어베언 관점
        <br />
        “긴장방출에 관한 정신분석학적 연구”- 하인즈 코핫 관점
      </Description>
    </Container>
  );
};

export const therapistList: Therapist[] = [
  {
    id: 4,
    name: "김옥환 선생님",
    image: ok,
    grade: "목회상담학/정신분석 박사 (Ph.D.)",
    description: <OkDescription />,
  },
  {
    id: 1,
    name: "김여환 선생님",
    image: yeo,
    grade: "목회상담학 박사(Ph.D.)",
    description: <YeoDescription />,
  },
  {
    id: 2,
    name: "서민지 선생님",
    image: seo,
    grade: "목회상담학 박사(Th.D.)",
    description: <SeoDescription />,
  },
  {
    id: 3,
    name: "노윤정 선생님",
    image: no,
    grade: "목회상담학 박사(Th.D.)",
    description: <NoDescription />,
  },
  {
    id: 5,
    name: "김현경 선생님",
    image: hyun,
    grade: "목회상담학 박사(Th.D.)",
    description: <HyunDescription />,
  },
  {
    id: 6,
    name: "김성하 선생님",
    image: sung,
    grade: "정신분석학 석사(Th.M.)",
    description: <SungDescription />,
  },
];
