import React from "react";

const Footer = () => {
  return (
    <div className="flex bg-main-400 flex flex-col text-white text-center text-sm lg:mb-0 py-4 leading-5">
      <span>수원가정심리상담소</span>
      <a href="tel:031-613-6565">TEL: 031-235-5573</a>
      <span>사업자번호: 876-97-00260 대표자:김옥환</span>
      <span>
        주소: 경기도 수원시 곡반정동 511-1번지(동수원로 146번길 115) 5층
      </span>
      <span>COPYRIGHT©Suwon Family Counseling Center.</span>
      <span>ALL RIGHTS RESERVED.</span>
    </div>
  );
};

export default Footer;
